<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        2work
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 v-if="is_success" class="mb-1 text-success">
           Готово!
        </h2>  
        <h2 v-if="is_error" class="mb-1 text-danger">
          Произошла ошибка
        </h2>
        <p class="mb-2">
            {{ message }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Вернуться на главную
        </b-button>

        <!-- image -->
    
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      message: "",
      is_success: false,
      is_error: false,
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  
  mounted() {
      
      this.is_error = false;
      this.is_success = false;
      
      this.$request.post("user.changeEmail", {
          link: this.$route.params.link
      }).then( result => {
          this.is_success = true;
          this.message = "Ваш адрес электронной почты успешно изменен.";
          this.$user.get();
      }).catch(err => {
         this.message = err.message; 
         this.is_error = true;
      });
      
  },
  
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
